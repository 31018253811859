<template>
  <div class="layout">
    <h2>{{ obj.newsTitle }}</h2>
    <p class="time">{{ obj.updateTime }}</p>
    <div class="content" v-html="obj.newsContent"></div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/validate";
export default {
  name: "mine",

  data() {
    return {
      obj: {},
    };
  },

  mounted() {},
  created() {
    this.getStore();
  },

  methods: {
    async getStore() {
      await this.$API.platform
        .NewsDetails({
          object: {
            newsCode: this.$route.query.newsCode,
          },
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") return false;
          this.obj = res.data.data;
          this.obj.updateTime = formatDate(this.obj.updateTime);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  text-align: center;
}
h2 {
  margin: 16px 50px 4px 50px;
  font-size: 16px;
  color: #333;
  line-height: 26px;
}
.time {
  color: #666;
}
.content {
  text-align: left;
  color: #999;
  margin: 16px 0;
  padding: 0 16px;
}
</style>
<style lang="scss">
img {
  max-width: 100%;
}
</style>